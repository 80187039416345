import { render, staticRenderFns } from "./leave.vue?vue&type=template&id=a4c5e24e&scoped=true"
import script from "./leave.vue?vue&type=script&lang=js"
export * from "./leave.vue?vue&type=script&lang=js"
import style0 from "./leave.vue?vue&type=style&index=0&id=a4c5e24e&prod&lang=scss&scoped=true"
import style1 from "./leave.vue?vue&type=style&index=1&id=a4c5e24e&prod&lang=scss&scoped=true"
import style2 from "./leave.vue?vue&type=style&index=2&id=a4c5e24e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c5e24e",
  null
  
)

export default component.exports