<template>
  <div class="customize-main">
    <div class="right-title">
      Date of Application 申请日期：{{ CreateDate }}
    </div>
    <table
      class="customize-form"
      v-if="departmentList && companyList && departmentList && projectList"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <td class="text-center">
            <img class="tbale-img1" src="@/assets/image/table/table-img2.jpg" />
          </td>
          <td class="text-center" colspan="4">
            <h1>中东公司员工请假申请表 Employee Leave Application</h1>
          </td>
          <td class="text-center">
            <p>S/N:{{ ApplicationNumber }}</p>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">Employee ID</div>
            <div class="nameEn">员工编码</div>
          </td>
          <td class="text-center">
            <el-input
              v-model="EmployeeCode"
              @blur="blurEmployeeCode"
              placeholder="请输入员工编码"
            ></el-input>
          </td>
          <td class="text-center">
            <div class="name">Employee Name</div>
            <div class="nameEn">员工姓名</div>
          </td>
          <td class="text-center">
            <div class="name name-small">{{ EmployeeName }}</div>
          </td>
          <td class="text-center">
            <div class="name">Job Profession</div>
            <div class="nameEn">工作岗位</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ JobProfession | positionFormater(positionList) }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">Project Name</div>
            <div class="nameEn">项目名称</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ ProjectID | projectFormater(projectList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name">Working Unit</div>
            <div class="nameEn">用工单位</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ CompanyID | companyFormater(companyList) }}
            </div>
          </td>
          <td class="text-center">
            <div class="name">Working Department</div>
            <div class="nameEn">部门名称</div>
          </td>
          <td class="text-center">
            <div class="name name-small">
              {{ DepartmentID | departmentFormater(departmentList) }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <div class="name">Leaving Typle</div>
            <div class="nameEn">请假类型</div>
          </td>
          <td class="text-center">
            <div class="name">Date From</div>
            <div class="nameEn">起始日期</div>
          </td>
          <td class="text-center">
            <div class="name">End Date</div>
            <div class="nameEn">结束日期</div>
          </td>
          <td class="text-center">
            <div class="name">Number of Leave Days</div>
            <div class="nameEn">请假天数</div>
          </td>
          <td class="text-center" colspan="2">
            <div class="name">Remark</div>
            <div class="nameEn">备注</div>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-select
              v-model="detailsItem.TypeID"
              placeholder="请选择"
              @change="changeTypeID"
              filterable
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.allName"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-date-picker
              v-model="detailsItem.StartDate"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsStart"
              type="date"
              placeholder="选择日期"
              @change="changeDate"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              v-model="detailsItem.EndDate"
              value-format="yyyy-MM-dd"
              :disabled="!detailsItem.StartDate"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptionsEnd"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <div>{{ outGoingDuration }}</div>
          </td>
          <td class="text-center" colspan="2">
            <el-input v-model="detailsItem.Remark"></el-input>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size">
            <div class="name">Note说明</div>
          </td>
          <td class="text-left text-size" colspan="5">
            <div class="note-box">
              <div>
                Employee should provide relevant documents to support Sick
                leave, bereavement leave, Child of birth leave etc., otherwise
                it will be regarded as personal leave.
              </div>
              <div>病假、丧假、护理假等需附相关证明材料，否则视为事假。</div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size">
            <div class="name">Approved by Manager</div>
            <div class="name">单位/部门领导意见</div>
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size">
            <div class="name">Approved by SNEMC/Project Authorized</div>
            <div class="name">中东公司或项目授权人审批</div>
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-size">
            <div class="name">HR/Admin. Verification</div>
            <div class="name">人力资源部登记备案</div>
          </td>
          <td class="text-left text-size" colspan="5">
            <el-input type="textarea" :rows="2" placeholder="请输入内容">
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img class="sign" v-if="false" :src="RequestDepartment" />
              </div>
              <div><span>日期Date：</span><span></span></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  addLeaveApplication2,
  editLeaveApplication2,
  getProjectAll,
  getDepartmentAll,
  allPosition,
  getCompanyAll,
  getUsersCode,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
  },
  props: {
    CreateDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projectList: [],
      departmentList: [],
      positionList: [],
      companyList: [],
      ID: null,
      KeyID: null,
      EmployeeID: null,
      EmployeeCode: null,
      EmployeeName: null,
      JobProfession: null,
      JobProfessionName: null,
      ProjectID: null,
      ProjectName: null,
      CompanyID: null,
      WorkingUnit: null,
      DepartmentID: null,
      WorkingDepartment: null,
      Note: null,
      EditState: 1,
      ApplicationNumber: null,
      detailsItem: {
        ID: null,
        KeyID: null,
        EmployeeLeaveApplicationKeyID: null,
        TypeID: null,
        TypeName: null,
        StartDate: null,
        EndDate: null,
        NumberOfLeaveDays: null,
        Remark: null,
      },
      Attachments: null,
      options: [
        {
          name: "病假",
          nameEn: "Sick Leave",
          allName: "病假(Sick Leave)",
          value: 1,
        },
        {
          name: "事假",
          nameEn: "Personal Leave",
          allName: "事假(Personal Leave)",
          value: 2,
        },
        {
          name: "工伤",
          nameEn: "Job Injuries",
          allName: "工伤(Job Injuries)",
          value: 3,
        },
        {
          name: "婚假",
          nameEn: "Marriage Leave",
          allName: "婚假(Marriage Leave)",
          value: 4,
        },
        {
          name: "丧假",
          nameEn: "Bereavement Leave",
          allName: "丧假(Bereavement Leave)",
          value: 5,
        },
        {
          name: "护理假",
          nameEn: "Child Birth Leave",
          allName: "护理假(Child Birth Leave)",
          value: 6,
        },
        {
          name: "朝觐",
          nameEn: "Hajj Leave",
          allName: "朝觐(Hajj Leave)",
          value: 7,
        },
        {
          name: "育儿假",
          nameEn: "Parental Leave",
          allName: "育儿假(Parental Leave)",
          value: 8,
        },
        {
          name: "独生子女护理假",
          nameEn: "Only Child Care Leave",
          allName: "独生子女护理假(Only Child Care Leave)",
          value: 9,
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.detailsItem.EndDate) {
            return (
              time.getTime() > new Date(this.detailsItem.EndDate).getTime() ||
              time.getTime() <=
                new Date(this.detailsItem.StartDate).getTime() - 86400000
            );
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.detailsItem.StartDate) {
            return (
              time.getTime() < new Date(this.detailsItem.StartDate).getTime()
            );
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    outGoingDuration() {
      let timer = null;
      if (this.detailsItem.StartDate && this.detailsItem.EndDate) {
        timer = this.compareDates(
          this.detailsItem.StartDate,
          this.detailsItem.EndDate
        );
      }
      this.detailsItem.NumberOfLeaveDays = timer + 1;
      return timer ? this.detailsItem.NumberOfLeaveDays + "天" : null;
    },
  },
  methods: {
    //创建时接收数据
    getCreateData(data1, data2, data3, data4) {
      //
    },
    //通过员工编码获取请假人信息
    blurEmployeeCode() {
      if (this.EmployeeCode) {
        getUsersCode({ code: this.EmployeeCode }).then((res) => {
          console.log("res", res);
          if (res.status === 200 && res.response) {
            const Employee = res.response;
            this.EmployeeID = Employee.ID;
            this.EmployeeName = Employee.ChineseName;
            this.JobProfession = Employee.PositionID;
            this.ProjectID = Employee.ProjectID;
            this.CompanyID = Employee.CompanyID;
            this.DepartmentID = Employee.DepartmentID;
          } else {
            this.$message.warning("员工编号不存在");
            this.EmployeeCode = null;
            this.EmployeeID = null;
            this.EmployeeName = null;
            this.JobProfession = null;
            this.ProjectID = null;
            this.CompanyID = null;
            this.DepartmentID = null;
          }
        });
      }
    },
    //判断选中请假类型
    changeTypeID() {
      this.options.forEach((element) => {
        if (element.value === this.detailsItem.TypeID) {
          this.detailsItem.TypeName = element.allName;
        }
      });
      console.log("11111", this.detailsItem);
    },
    //判断日期是否有误
    changeDate(e) {
      console.log("e", e);
      if (e) {
        let currentTime = new Date(),
          year = currentTime.getFullYear(),
          month =
            currentTime.getMonth() + 1 < 10
              ? "0" + (currentTime.getMonth() + 1)
              : currentTime.getMonth() + 1,
          day =
            currentTime.getDate() < 10
              ? "0" + currentTime.getDate()
              : currentTime.getDate();
        let nowDate = year + "-" + month + "-" + day;

        var currentDate = new Date(nowDate);
        // 获取待比较的日期
        var compareDate = new Date(e);
        if (compareDate >= currentDate) {
          console.log("日期大于当前日期");
        } else {
          this.$message.error("开始日期不能小于当前日期");
          this.detailsItem.StartDate = null;
        }

        if (this.detailsItem.EndDate) {
          var compareEndDate = new Date(this.detailsItem.EndDate);
          if (compareEndDate <= compareDate) {
            this.$message.error("开始日期不能大于或等于结束日期");
            this.detailsItem.StartDate = null;
          }
        }
      }
    },
    //计算两个日期相差天数
    compareDates(date1, date2) {
      // 将字符串转换为Date对象
      var d1 = new Date(date1);
      var d2 = new Date(date2);

      // 获取时间戳（单位：毫秒）
      var timeDiff = Math.abs(d2.getTime() - d1.getTime());

      // 计算天数差
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays + 1;
    },
    //保存
    addManpowerRequest(type) {
      if (!this.detailsItem.TypeID) {
        this.$message.warning("请选择请假类型");
        return;
      }
      if (!this.detailsItem.StartDate) {
        this.$message.warning("请选择开始日期");
        return;
      }
      if (!this.detailsItem.EndDate) {
        this.$message.warning("请选择结束日期");
        return;
      }
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        EmployeeID: this.EmployeeID,
        EmployeeCode: this.EmployeeCode,
        EmployeeName: this.EmployeeName,
        JobProfession: this.JobProfession,
        JobProfessionName: this.JobProfessionName,
        ProjectID: this.ProjectID,
        ProjectName: this.ProjectName,
        CompanyID: this.CompanyID,
        WorkingUnit: this.WorkingUnit,
        DepartmentID: this.DepartmentID,
        WorkingDepartment: this.WorkingDepartment,
        Note: this.Note,
        EditState: type,
        Attachments: this.Attachments,
        Details: [],
      };
      data.Details.push(this.detailsItem);
      if (!data.ID) {
        addLeaveApplication2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editLeaveApplication2(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },

    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.EmployeeID = null;
      this.EmployeeCode = null;
      this.EmployeeName = null;
      this.JobProfession = null;
      this.JobProfessionName = null;
      this.ProjectID = null;
      this.ProjectName = null;
      this.CompanyID = null;
      this.WorkingUnit = null;
      this.DepartmentID = null;
      this.WorkingDepartment = null;
      this.Note = null;
      this.Attachments == null;
      this.WorkingDepartment = null;
      this.detailsItem = {
        ID: null,
        KeyID: null,
        EmployeeLeaveApplicationKeyID: null,
        TypeID: null,
        TypeName: null,
        StartDate: null,
        EndDate: null,
        NumberOfLeaveDays: null,
        Remark: null,
      };
    },
    //编辑回显
    getEditData(data) {
      console.log("data", data);
      this.ID = data.ID;
      this.KeyID = data.KeyID;
      this.EmployeeID = data.EmployeeID;
      this.EmployeeCode = data.EmployeeCode;
      this.EmployeeName = data.EmployeeName;
      this.JobProfession = data.JobProfession;
      this.ProjectID = data.ProjectID;
      this.CompanyID = data.CompanyID;
      this.DepartmentID = data.DepartmentID;
      this.ApplicationNumber = data.ApplicationNumber;
      data.Details[0].EndDate = data.Details[0].EndDate.slice(0, 10);
      data.Details[0].StartDate = data.Details[0].StartDate.slice(0, 10);
      this.detailsItem = data.Details[0];
      this.Attachments = data.Attachments;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.right-title {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.customize-main {
  width: 100%;
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .text-center {
      text-align: center;
      .tbale-img1 {
        width: 200px;
      }
      h1 {
        font-size: 28px;
        color: #000;
        margin: 10px 0;
      }
      p {
        font-size: 16px;
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .nameEn {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
    }
    .text-left {
      .flex {
        display: flex;
        font-size: 16px;
        color: #000;
        align-items: center;
        margin: 10px 0;
        .el-checkbox {
          font-size: 18px;
          color: #000;
          margin-right: 10px;
        }
        .input-box {
          width: 100px;
          border-bottom: 1px solid #000;
          margin: 0 10px;
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 50%;
        }
        .sign-box {
          display: flex;
          align-items: center;
        }
      }
      .name {
        font-size: 18px;
        margin: 10px 0;
        color: #000;
        font-weight: bolder;
      }
      .el-checkbox {
        margin-right: 0;
      }
    }
    .text-size {
      font-size: 16px;
      color: #000;
      text-align: center;
      max-width: 200px;
      .el-checkbox {
        margin: 10px 0;
      }
      span {
        margin-right: 20px;
      }
      div {
        margin: 10px 0;
      }
      .input-box {
        display: inline-block;
        width: 100px;
        border-bottom: 1px solid #000;
        margin: 10px 0;
      }
    }
  }
  .customize-form2 {
    border-top: none;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
}
.note-box {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.name-small {
  font-weight: normal !important;
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.el-checkbox >>> .el-checkbox__inner {
  background-color: #fff !important;
  border-color: #000 !important;
}
.el-checkbox >>> .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border-color: #000;
}
.sign {
  width: 100%;
  max-width: 100px;
}
</style>